/**
 * @file Wrapper for Google reCAPTCHA (v2 and v3) functions.
 * @see https://developers.google.com/recaptcha/intro
 * @see https://developers.google.com/recaptcha/docs/v3
 * @see https://developers.google.com/recaptcha/docs/display (v2)
 */

/**
 * Render the recaptcha v2 element that the user has to solve.
 * We have to render the recaptcha v2 element programmatically, so it does not
 * interfere with recaptcha v3.
 * @param challengeElement
 */
export function renderV2WhenReady(challengeElement) {
  ready(() => {
    renderV2(challengeElement);
  });
}

/**
 * Renders multiple recaptcha v2 elements that the user has to solve.
 * This is necessary for the login/register popup.
 * TODO Refactor that we don't use window variable, because using global vars
 *      in modules is considered bad practice.
 * TODO Clarify the behaviour of this, do we give multiple elements or not?
 * @param challengeElements
 */
export function renderMultipleCaptchasOnSamePage(challengeElements) {
  ready(() => {
    window.recaptchaIds.push(renderV2(challengeElements));
  });
}

/**
 * Executes the recaptcha v3 challenge.
 * @see https://developers.google.com/recaptcha/docs/v3#actions
 * @param {string} action will be visible in the admin console of Google
 * @param {function} callback
 */
export function execute(action, callback) {
  const captchaKey = document.getElementById(
      'js-data-container').dataset.captchaKey;
  grecaptcha.execute(captchaKey, {action: action}).then(callback);
}

function getPageId() {
  return grecaptcha.getPageId();
}

function getResponse() {
  return grecaptcha.getResponse();
}

/**
 * Executes the callback when Google recaptcha is ready.
 * @param {function} callback
 */
function ready(callback) {
  grecaptcha.ready(callback);
}

/**
 * Renders the recaptcha v2 element that the user has to solve.
 * @param elements
 * @returns {number} The captcha id
 */
function renderV2(elements) {
  const captchaKeyV2 = document.getElementById(
      'js-data-container').dataset.captchaKeyV2;
  return grecaptcha.render(elements, {
    'sitekey': captchaKeyV2,
  });
}

/**
 * Resets the recaptcha.
 * @param {number} recaptchaId
 */
export function reset(recaptchaId) {
  grecaptcha.reset(recaptchaId);
}